import React from 'react';
import { graphql } from 'gatsby';
import ImportComponent from './import-component';
import { ErrorModule } from './error-module';
import { SanityPageBuilderComponentsList } from '../../../../graphql-types';
import { Layout, Module } from '../..';
import { useSanityModuleSettings } from '../../../hooks/use-sanity-module-settings';
import { NavbarType } from 'src/redesign/layouts/nav-switcher';
import { FooterContentKey } from 'src/redesign/types';

export interface PageBuilderBaseProps {
  components: SanityPageBuilderComponentsList;
  navType?: NavbarType;
  customNav?: React.ReactNode;
}

export default function PageBuilderBase({
  components,
  navType = 'MAIN_SITE',
  customNav
}: PageBuilderBaseProps): React.ReactElement {
  const { getModuleSettings } = useSanityModuleSettings();

  const { items } = components;
  console.log(items);
  return (
    <Layout
      navbarType={navType}
      footerType={navType}
      footerContentKey={navType as FooterContentKey}
      customNav={customNav}
    >
      {items.flatMap((props = {}, index: any) => {
        const { _type, _key, moduleSettings, ...componentProps } = props;
        const { elementId, theme, margins } = getModuleSettings(
          moduleSettings || {}
        );
        const moduleId = elementId || _key || `module-${index}`;
        return _type ? (
          <Module id={moduleId} key={moduleId} theme={theme} {...margins}>
            <ImportComponent
              __typename={_type}
              componentProps={componentProps}
            />
          </Module>
        ) : (
          <ErrorModule typename={_type} filePath={_type} />
        );
      })}
    </Layout>
  );
}

export const query = graphql`
  fragment SanityPageBuilderComponentsListFragment on SanityPageBuilderComponentsList {
    items {
      __typename
      ... on SanityCardCarouselAndSlideAccordion {
        ...SanityCardCarouselAndSlideAccordionFragment
      }
      ... on SanityContinuedTextList {
        ...SanityContinuedTextListFragment
      }
      ... on SanityDataListAndChart {
        ...SanityDataListAndChartFragment
      }
      ... on SanityAccordeonModule {
        ...SanityAccordeonModuleFragment
      }
      ... on SanityHalfAndHalfStat {
        ...SanityHalfAndHalfStatFragment
      }
      ... on SanityQuotesCarousel {
        ...SanityQuotesCarouselFragment
      }
      ... on SanitySingleQuote {
        ...SanitySingleQuoteFragment
      }
      ... on SanityConditionsModule {
        ...SanityConditionsModuleFragment
      }
      ... on SanityThirdPartyQuotesCarousel {
        ...SanityThirdPartyQuotesCarouselFragment
      }
      ... on SanityHalfAndHalfModule {
        ...SanityHalfAndHalfModuleFragment
      }
      ... on SanityStandardCarousel {
        ...SanityStandardCarouselFragment
      }
      ... on SanityHalfAndHalfCollapsibleDataList {
        ...SanityHalfAndHalfCollapsibleDataListFragment
      }
      ... on SanityStatisticStructuredBox {
        ...SanityStaticStructureBoxFragment
      }
      ... on SanityDoubleStatisticStructuredBox {
        ...SanityDoubleStaticStructureBoxFragment
      }
      ... on SanityFeaturedArticles {
        ...SanityFeaturedArticlesModuleFragment
      }
      ... on SanityHeroModule {
        ...SanityHeroModuleFragment
      }
      ... on SanityFaqModule {
        ...SanityFaqModuleFragment
      }
      ... on SanityScheduleCall {
        ...SanityScheduleCallFragment
      }
      ... on SanityStructured {
        ...SanityStructuredFragment
      }
      ... on SanityHalfAndHalfNumberedList {
        ...SanityHalfAndHalfNumberedListFragment
      }
      ... on SanityEmployerForm {
        ...SanityEmployerFormFragment
      }
      ... on SanityLogoCloud {
        ...SanityLogoCloudFragment
      }
      ... on SanityHeroTextOnlyModule {
        ...SanityHeroTextOnlyFragment
      }
      ... on SanityMembershipHeroModule {
        ...SanityMembershipHeroFragment
      }
      ... on SanityComparisonTableModule {
        ...SanityComparisonTableFragment
      }
      ... on SanitySamplePlanCarouselModule {
        ...SanitySamplePlanCarouselFragment
      }
    }
  }
`;
